:root {
  --background-color: #f2f2f2;
  --sidebar-background: #f0f0f0;
  --sidebar-button: transparent;
  --text-white: #fff;
  --text-black: #000;
  --indicator: #ffc30f;
  --main-color: #5a4bb7;
  --confirm-green: #34b233;
  --bento-background: #fff;
  font-family:sans-serif
}

/*ANIMATIONS*/
@keyframes fadeInUp {
  from {
    opacity: 0.4;
    /* transform: translateY(1rem);  */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}
html,body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.dash-app {
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
}
.dash-content {
  background-color: var(--background-color);
  transition: margin-left .3s, width .3s;
  display:flex;
  align-items:center;
  justify-content: center;

  /* min-height: 100%; */
}

 /* SIDEBAR */

.sidebar {
  user-select: none;
  background-color: var(--sidebar-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  max-width: 15rem;
  height: 100%;
  transition: margin-left .3s;
  /* box-shadow: 0 0 0.5rem black; */
  border-right: 2px solid gray;
  z-index: 100;
  position: absolute;
}
.sidebar-hamburger {
  position: absolute;
  top: 0rem;
  right: -3.1rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
  background-color: var(--sidebar-background);
  border-right: 2px solid gray;
  border-bottom: 2px solid gray;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0rem 0rem 0.5rem 0rem;
}
.sidebar-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 4rem;

}
.sidebar-header-img {
  width: 70%;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 12rem;
  height: 100%;
  margin-left: 2rem;
  padding-right: 3rem;
}
.sidebar-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 12rem;
  height: 3rem;


}
.sidebar-item {
  box-sizing: border-box;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 12rem;
  padding: 1rem;
  text-align: left;
  background-color: var(--sidebar-button);
  cursor: pointer;
  color: var(--text-black);
}
.sidebar-item.bottom {
  width: 100%;
  padding: 1rem;
  text-align: left;
  background-color: var(--sidebar-button);
  cursor: pointer;
  color: var(--text-black);
}
.sidebar-item-text {
  width: 8rem;
}
.sidebar-indicator {
  width: 1rem;
  height: 140%;
  margin-left: -2rem;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: background-color 0.3s

}

.green-button{
  box-sizing:border-box;
  border: 2px solid var(--confirm-green);
}
.green-button:hover{
  cursor: pointer;
  border: 2px solid black;
  background-color: var(--confirm-green);
  color:white;
}


.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 1rem;
}

.styled-table th, .styled-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.styled-table th {
  background-color: #5a4bb7;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tr:hover {
  background-color: #ddd;
}

.styled-table td {
  color: #333;
}

.styled-table th:first-child,
.styled-table td:first-child {
  border-left: none;
}

.styled-table th:last-child,
.styled-table td:last-child {
  border-right: none;
}




.clickable {
  user-select: none;
  cursor: pointer;
}



.alert-switch-container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.alert-switch-option {
  flex: 1;
  text-align: center;
  padding: 8px;
  font-size: 1rem;
  /* font-weight: bold; */
  cursor: pointer;
  background: #f5f9ff;
  transition: background 0.1s ease-in-out;
  user-select: none;
}

.alert-switch-option input {
  display: none;
}

.alert-switch-option.selected {
  background: #4caf50; /* Green for selected */
  color: white;
}

.alert-switch-option:hover {
  background: #e0e0e0;
}

.alert-switch-option:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.alert-switch-option:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


.pop-up-window {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pop-up-pane {
  border-radius: 10;
  border: 2px solid gray;
  background-color: var(--background-color);
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: black;
}
.pop-up-icon{

}
.pop-up-message{

}
.pop-up-button{
  border-radius: 2;
  border: 2px solid gray;
  width: 50%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.pop-up-button:hover{
  background-color: #e0e0e0;
}